.navgation {
  display: flex;
  padding-left: 80%;
}

.card-text {
  overflow-wrap: break-word;
  overflow: hidden;
}

.depart-section {
  display: flex;
  background-color: rgba(250, 250, 250, 0.992);
  flex-direction: column;
  padding-bottom: 20px;
}

.portal-main-section {
  background-color: rgba(250, 250, 250, 0.992);
}

.fh-card-img {
  -o-object-fit: cover;
     object-fit: cover;
}

.cards {
  cursor: pointer;
  float: left;
  height: 325px;
  width: 260px;
  background-color: white;
  border: 1px solid #e4dfdf;
  margin: 7px;
  border-radius: 12px;
}

.cards:hover {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
}

.portal-full-section {
  background-color: rgba(250, 250, 250, 0.992);
  min-height: 42rem;
}

.c-section {
  padding-left: 14rem;
  padding-right: 10rem;
}

@media (max-width: 720px) {
  .c-section {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (max-width: 1048px) {
  .c-section {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.portal-full-section {
  margin: 0 auto;
}

@media (min-width: 1440px) {
  .portal-full-section {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    max-width: 1290px;
  }
}
.abbr-name {
  font-size: 2rem;
  color: #a6b1b9;
  height: 220px;
}

.portal-placeholder-div {
  padding: 85px;
  text-align: center;
  background-color: #f9fafa;
  border-top-left-radius: calc(0.75rem - 1px);
  border-top-right-radius: calc(0.75rem - 1px);
}

.card-img-top, .portal-placeholder-div {
  height: 220px;
}

.dep-description {
  font-size: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
}

.patient-app-img {
  height: 5%;
  width: 5%;
  border-radius: 50%;
}

.btn-appointment {
  background-color: white !important;
  margin: 0 10px 10px 0;
  width: auto;
}

.btn-appointment:hover:not([disabled]) {
  color: #ffffff;
  background-color: #a8a5a5 !important;
}

.btn-appointment:hover:disabled {
  color: #c0bcbc;
}

.btn-appointment:disabled,
.btn-appointment[disabled] {
  color: #c0bcbc;
}

.appointment-dep {
  font-size: 13px;
}

.success-page {
  background-color: var(--fg-color);
  padding: 5rem 2rem;
  margin-top: 3rem;
  border: 1px solid var(--dark-border-color);
  border-radius: var(--border-radius);
  text-align: center;
}
.success-page .success-header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1rem;
}
.success-page .success-header .success-icon {
  width: 3rem;
  height: 3rem;
  margin: 0;
}
.success-page .success-header .success-title {
  margin-top: 0;
  margin-bottom: 0;
}
.success-page .success-body .success-message {
  margin: 1rem 0rem 1.5rem;
}
.success-page .success-footer a {
  margin: 0rem 0.3rem 1rem;
}

@media (min-width: 700px) {
  .fh-btn-grp {
    float: left;
  }
}
.fh-btn-grp {
  float: right;
  padding-bottom: 20px;
}

@media (max-width: 740px) {
  .nav-item {
    max-width: 100px !important;
    display: inline;
  }
}
.practitioner-btn, .department-btn {
  cursor: pointer;
  border: 1px solid #DCE0E3;
  margin: 0px;
  font-weight: 300;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.practitioner-btn {
  border-radius: 0rem 0.5rem 0.5rem 0;
}

.department-btn {
  border-radius: 0.5rem 0rem 0rem 0.5rem;
}

.fh-active-btn {
  outline-color: #DCE0E3 !important;
  background-color: #F4F5F6;
}

.fh-disabled-btn:hover {
  background-color: #F4F5F6;
}

.fh-card-text {
  padding-top: 4px;
}

.practitioner-text {
  display: block;
}

.hide-sec {
  display: none;
}

.freeze-div {
  filter: blur(0.6px);
  pointer-events: none;
}

.normal-bc-link {
  color: black;
}

.selected-bc-link {
  color: #afadad;
}

.select-patient-sec {
  padding-bottom: 20px;
}

.fh-slot-container, .fh-slot-container-inner, .depart-div, .pract-div {
  text-align: center;
}

.opt-out-conf-div {
  margin: 0px auto;
}

.opt-out-title {
  display: block;
  padding-bottom: 10px;
}

.opt-out-description {
  display: block;
  font-size: 12px;
}

.appointment-success-div {
  padding: 5rem 30rem 5rem 30rem;
  display: none;
}

.fh-no-cards {
  text-align: center;
  vertical-align: middle;
}